import {
	nextTick
} from 'vue';

/**
 * 文本溢出截取，兼容性好  需要给元素设置height和line-height，超出指定长度省略并展示...，未超出则不处理。
 * 弊端：对宽度响应式变化的页面展示效果不友好，a标签可能会截取一半
 * 
 * @param el 指令作用的元素
 */
export function textOverflow(el) {
	nextTick(() => {
		let text = el.innerHTML;
		//过滤掉字符串中的 img 标签

		let imgReg = /<(img|input|br|hr|p|video)[^>]*>/gi; // 匹配图片中的img、input等标签
		text = text.replace(imgReg, ''); // replaceAll 替换符合条件的内容为空串

		// 将末尾的三个文字用...取代，同时跳出for循环
		if (!text || !text.length) return;

		for (let i = 0; i <= text.length; i++) {
			el.innerHTML = text.substr(0, i);

			if (el.offsetHeight < el.scrollHeight) {
				el.style.overflow = 'hidden';
				// 截取当前字符串0-倒数第5的区间位置的字符并加上"..."，然后赋值给元素的innerHTML
				el.innerHTML = text.substr(0, i - 5) + '...';
				break;
			}
		}
	});
}
